export const menuItems = [
  {
    id: "5",
    to: '/privacy-policy',
    text: 'Privacy policy',
  },
  {
    id: "6",
    to: '/terms',
    text: 'Terms of services'
  },]