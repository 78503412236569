// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_container__ZdBeZ {\n  position: relative;\n  min-height: calc(100vh - 290px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media screen and (min-width: 768px) {\n  .App_container__ZdBeZ {\n    min-height: calc(100vh - 367px);\n  }\n\n}", "",{"version":3,"sources":["webpack://./src/components/App.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,+BAA+B;EACjC;;AAEF","sourcesContent":[".container {\n  position: relative;\n  min-height: calc(100vh - 290px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media screen and (min-width: 768px) {\n  .container {\n    min-height: calc(100vh - 367px);\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "App_container__ZdBeZ"
};
export default ___CSS_LOADER_EXPORT___;
