export const menuItems = [
  {
    id: "1",
    to: '/',
    text: 'Main',
  },
  {
    id: "2",
    to: '/games',
    text: 'Games',
  },
  {
    id: "3",
    to: '/about-company',
    text: 'About Company'
  },
  {
    id: "4",
    to: '/support',
    text: 'Support',
  },
];