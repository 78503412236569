// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_logo__vDBPO {\n  width: 60px;\n  height: 60px;\n}\n\n@media screen and (max-width: 767px) {\n  .Logo_logo__vDBPO {\n    width: 50px;\n    height: 50px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".logo {\n  width: 60px;\n  height: 60px;\n}\n\n@media screen and (max-width: 767px) {\n  .logo {\n    width: 50px;\n    height: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo_logo__vDBPO"
};
export default ___CSS_LOADER_EXPORT___;
