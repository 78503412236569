// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__VVOCq {\n  position: relative;\n  min-width: 320px;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 0 20px;\n  height: 100%;\n}\n@media screen and (min-width: 480px) {\n  .Container_container__VVOCq {\n    width: 480px;\n  }\n}\n@media screen and (min-width: 768px) {\n  .Container_container__VVOCq {\n    width: 768px;\n    padding: 0 32px;\n  }\n}\n@media screen and (min-width: 1200px) {\n  .Container_container__VVOCq {\n    width: 1200px;\n    padding: 0 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;AACA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;IACZ,eAAe;EACjB;AACF;AACA;EACE;IACE,aAAa;IACb,eAAe;EACjB;AACF","sourcesContent":[".container {\n  position: relative;\n  min-width: 320px;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 0 20px;\n  height: 100%;\n}\n@media screen and (min-width: 480px) {\n  .container {\n    width: 480px;\n  }\n}\n@media screen and (min-width: 768px) {\n  .container {\n    width: 768px;\n    padding: 0 32px;\n  }\n}\n@media screen and (min-width: 1200px) {\n  .container {\n    width: 1200px;\n    padding: 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__VVOCq"
};
export default ___CSS_LOADER_EXPORT___;
